import axios from "axios";

const request = axios.create({
    baseURL: 'https://api-zs.collapse.cool/mock',
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default request;
