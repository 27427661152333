<template>
  <div id="app">
    <h3>芝士校园活动账号生成器</h3>
    <el-button v-show="tableData.length === 0" type="primary" @click="generateAccount">生成账号</el-button>
    <el-table v-show="tableData.length > 0" :data="tableData" class="table">
      <el-table-column prop="username" label="用户名" align="center"/>
      <el-table-column prop="password" label="密码" align="center"/>
    </el-table>
    <div class="copy-button">
      <el-button v-show="tableData.length > 0" @click="generateAccount">重新生成</el-button>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";

export default {
  name: 'App',
  data() {
    return {
      tableData: [],
    }
  },
  methods: {
    generateAccount() {
      this.tableData = [];
      request.get('/generator').then(res => {
        this.tableData.push(res.data.data);
        request.delete(`/generator?username=${res.data.data.username}`)
      });
    }
  }
}
</script>

<style>
#app {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table {
  height: 400px;
}

.copy-button {
  position: absolute;
  bottom: 100px;
}
</style>
